:root {
	--color-primary-100: #d9ece2;
	--color-primary-200: #b2d9c5;
	--color-primary-300: #37d288;
	--color-primary-400: #00bb5b;
	--color-primary-500: #00803e;
	--color-primary-600: #004524;
	--color-primary-700: #003216;
	--color-primary-800: #001520;

	--color-secondary-100: #c8e9ff;
	--color-secondary-200: #8cd0ff;
	--color-secondary-300: #38aeff;
	--color-secondary-400: #2e90ff;
	--color-secondary-500: #2677d3;
	--color-secondary-600: #005585;
	--color-secondary-700: #024074;
	--color-secondary-800: #002856;

	--color-black: #000000;
	--color-gray-300: #f7f7f7;
	--color-gray-400: #e9e9e9;
	--color-gray-500: #bababa;
	--color-gray-600: #757575;
	--color-gray-700: #393939;

	// timer
	--color-background-timer: var(--color-secondary-500);
	--color-text-timer: white;

	// footer
	--color-background-footer: var(--color-primary-700);
	--color-text-footer: white;

	// badge inclus
	--color-background-badge-inclus: var(--color-primary-600);
	--color-text-badge-inclus: white;

	// push notification
	--color-background-push-notification: var(--color-secondary-600);

	// active color
	--color-active: var(--color-primary-500);
	--tab-active-color: var(--color-primary-600);

	// payment banner
	--payment-description-background-color: var(--color-primary-100);
	--payment-description-instalments-background-color: var(--color-primary-500);

	// choice
	--choice-background-color: var(--color-primary-600);

	// input-label
	--input-label-active-color: var(--color-gray-600);

	// picture shadow
	--picture-shadow-layer: rgba(
		0,
		21,
		32,
		0.3
	); // rgba(var(--color-primary-800), 0.3) ne fonctionne pas car CSS ne convertit pas directement
	// les couleurs hex en RGB. Utilisation manuelle de la valeur RGB équivalente.


	--color-header-bottom-border: var(--color-gray-400);
}
