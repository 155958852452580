@font-face {
	font-family: "saudia-regular";
	src: url("/static/font/saudia/SaudiaSans-Regular.woff2") format("woff2"),
		url("/static/font/saudia/SaudiaSans-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "saudia-bold";
	src: url("/static/font/saudia/SaudiaSans-Bold.woff2") format("woff2"),
		url("/static/font/saudia/SaudiaSans-Bold.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
